import React, { useEffect, useState } from "react";
import styles from "../styles/list.module.css";
import loginleft from "./../../../assets/images/loginleft.png"
import { Layout, Pagination } from "../../../ui";
import { useFormik } from "formik";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { ManualOrderSchema } from "../validation";
import 'react-phone-number-input/style.css'
import Resizer from 'react-image-file-resizer';
import { toast } from "react-toastify";
import { uploadImage } from "../api";

function Individualform() {

    const [disable, setDisable] = useState(false);
    const [deviceToken, setDeviceToken] = useState('');
    const [loader, setLoader] = useState<boolean>(false);
    const [images, setImages] = useState({ passportFront: "", passportBack: '', visa: '' });
    const formData = new FormData();

    const uploadFile = (formData: FormData, key: string) => {
        setLoader(true)
        toast.promise(
            uploadImage(formData),
            {
                pending: {
                    render() {
                        return 'Uploading File';
                    }
                },
                success: {
                    render({ data }) {
                        formik.setFieldValue(key, data?.data?.fileName);
                        setLoader(false)
                        setImages({ ...images, [key]: data.data.url });
                        return 'File uploaded successfully';
                    }
                },
                error: {
                    render({ data }: any) {
                        setLoader(false)
                        return 'Failed to upload File';
                    }
                }
            });
    };

    const handlePassportFrontChange = async (event: any) => {
        if (event.currentTarget.files) {
            const file = event.currentTarget.files[0];

            Resizer.imageFileResizer(
                file,
                300, // max width
                300, // max height
                'JPEG', // format
                100, // quality
                0, // rotation
                (uri: any) => {
                    const resizedFile = new File([uri], file.name, { type: file.type });
                    const formData = new FormData();
                    formData.append('file', resizedFile);
                    setDisable(true);
                    uploadFile(formData, 'passportFront');
                },
                'blob' // output type
            );
            setDisable(true)
        }
    };

    const handlePassportBackChange = async (event: any) => {
        if (event.currentTarget.files) {

            const file = event.currentTarget.files[0];

            Resizer.imageFileResizer(
                file,
                300, // max width
                300, // max height
                'JPEG', // format
                100, // quality
                0, // rotation
                (uri: any) => {
                    const resizedFile = new File([uri], file.name, { type: file.type });
                    const formData = new FormData();
                    formData.append('file', resizedFile);
                    setDisable(true);
                    uploadFile(formData, 'passportBack');
                },
                'blob' // output type
            );
            setDisable(true)
        }
    };

    const handleVisaChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files) {

            const file = event.currentTarget.files[0];

            Resizer.imageFileResizer(
                file,
                300, // max width
                300, // max height
                'JPEG', // format
                100, // quality
                0, // rotation
                (uri: any) => {
                    const resizedFile = new File([uri], file.name, { type: file.type });
                    const formData = new FormData();
                    formData.append('file', resizedFile);
                    setDisable(true);
                    uploadFile(formData, 'visa');
                },
                'blob' // output type
            );
            setDisable(true)
        }
    };


    const formik: any = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            bundleName: "",
            buyPrice: "",
            quantity: "",
            email: "",
            phoneNumber: "",
            countryCode: "",
            passportFront: '',
            passportBack: '',
            visa: ''
        },

        validationSchema: ManualOrderSchema,

        onSubmit: async (values, { setFieldError }) => {

            if (!isPossiblePhoneNumber(values.phoneNumber) || !isValidPhoneNumber(values.phoneNumber)) {
                setFieldError('phoneNumber', 'Invalid phone number');
                return;
            }

            const parsedNumber = parsePhoneNumber(values.phoneNumber);

            let payload: any = {
                firstName: values.firstName,
                lastName: values.lastName,
                bundleName: values.bundleName,
                email: values.email,
                countryCode: `+${parsedNumber?.countryCallingCode as string}`,
                phoneNumber: parsedNumber?.nationalNumber as string,
                buyPrice: values.buyPrice,
                quantity: values.quantity,
                documents: {
                    passportFront: values.passportFront,
                    passportBack: values.passportBack,
                    visa: values.visa
                }
            };

            // if (userDetail?.sub) {
            //     signupPayload.socialId = userDetail?.sub;
            // }

            // const formData = new FormData();
            // formData.append('file', signupPayload.userImage);

            // if (!signupPayload.isPhoneNoVerified) {
            //     formik.errors.phoneNumber = "Phone is not verified!"
            //     return null
            // }

            // if (!signupPayload.isEmailVerified) {
            //     formik.errors.email = "Email is not verified!"
            //     return null
            // }

            // const imageData: any = values.userImage;

            // if (imageData?.data?.statusCode === 400) {
            //     formik.errors.userImage = "Something wents wrong!"
            //     return false

            // } else if (imageData?.statusCode === 200) {
            //     signupPayload.avatar = imageData?.data.fileName;

            //     if (signupPayload.avatar === '') {
            //         formik.errors.userImage = "Something wents wrong!"
            //         return false
            //     }
            // }
            // setLoader(true)

            // toast.promise(
            //     signup(signupPayload),
            //     {
            //         pending: {
            //             render() {
            //                 return 'Trying to create user';
            //             }
            //         },
            //         success: {
            //             render({ data }) {
            //                 if (current_url) navigate(current_url)
            //                 loginUser(data.data.accessToken);
            //                 setLoader(false)
            //                 return 'signup Successful';
            //             }
            //         },
            //         error: {
            //             render({ data }: any) {
            //                 setLoader(false)
            //                 return data.data.message;
            //             }
            //         }
            //     });
        },
    });

    return (
        <Layout title="Orders">
            <div className={styles.IndiavalForm}>
                <h2>Individu al Form</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={styles.formGroup}>
                                <label>First Name</label>
                                <input type="text"
                                    placeholder="Name"
                                    name="firstName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                />
                                {formik.touched.firstName && formik.errors.firstName && (
                                    <div className={styles.error}>{formik.errors.firstName}</div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className={styles.formGroup}>
                                <label>Last Name</label>
                                <input type="text" placeholder="Name"
                                    name="lastName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                />
                                {formik.touched.lastName && formik.errors.lastName && (
                                    <div className={styles.error}>{formik.errors.lastName}</div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className={styles.formGroup}>
                                <label>Email</label>
                                <input type="email" placeholder="Name"
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className={styles.error}>{formik.errors.email}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={styles.formGroup}>
                                <label>Phone Number</label>
                                <PhoneInput
                                    placeholder="input your Number here"
                                    onChange={(value) => formik.setFieldValue('phoneNumber', value)}
                                    value={formik.values.phoneNumber}
                                    className={styles.phoneNumber}
                                // disabled={!phoneVerified}
                                />
                                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                    <div className={styles.error}>{formik.errors.phoneNumber}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={styles.formGroup}>
                                <label>Bundle Name</label>
                                <input type="text" placeholder="Name"
                                    name="bundleName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.bundleName}
                                />
                                {formik.touched.bundleName && formik.errors.bundleName && (
                                    <div className={styles.error}>{formik.errors.bundleName}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={styles.formGroup}>
                                <label>Price</label>
                                <input type="text" placeholder="Price"
                                    name="buyPrice"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.buyPrice}
                                />
                                {formik.touched.buyPrice && formik.errors.buyPrice && (
                                    <div className={styles.error}>{formik.errors.buyPrice}</div>
                                )}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className={styles.formGroup}>
                                <label>Quantity</label>
                                <input type="text" placeholder="Quantity"
                                    name="quantity"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.quantity}
                                />
                                {formik.touched.quantity && formik.errors.quantity && (
                                    <div className={styles.error}>{formik.errors.quantity}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={styles.formGroup}>
                            </div></div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.upoadImage}>

                                    {images.passportFront.length ?
                                        <div className={styles.ImagePrev}>
                                            {/* <i className="fas fa-times-circle"></i> */}
                                            <img src={images.passportFront} alt="" />
                                        </div> :
                                        <>
                                            <input
                                                type='file'
                                                name="passportFront"
                                                onChange={handlePassportFrontChange}
                                                onBlur={formik.handleBlur}
                                                readOnly={disable}
                                            />
                                            <div className={styles.preview}>
                                                <i className="fas fa-images"></i>
                                                <p>Add Image</p>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.upoadImage}>
                                    {images.passportBack.length ?

                                        <div className={styles.ImagePrev}>
                                            {/* <i className="fas fa-times-circle"></i> */}
                                            <img src={images.passportBack} alt="" />
                                        </div> :
                                        <>
                                            <input
                                                type='file'
                                                readOnly={disable}
                                                name="passportBack"
                                                onChange={handlePassportBackChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className={styles.preview}>
                                                <i className="fas fa-images"></i>
                                                <p>Add Image</p>
                                            </div>
                                        </>
                                    }


                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.upoadImage}>
                                    {images.visa.length ?
                                        <div className={styles.ImagePrev}>
                                            {/* <i className="fas fa-times-circle"></i> */}
                                            <img src={images.visa} alt="" />
                                        </div>
                                        :
                                        <>
                                            <input type='file'
                                                readOnly={disable}
                                                name="visa"
                                                onChange={handleVisaChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className={styles.preview}>
                                                <i className="fas fa-images"></i>
                                                <p>Add Image</p>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <input type="submit" className={"btn btn-success"} placeholder="" value={"Submit"} />
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default Individualform